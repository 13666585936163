<template>
  <layout :title="$route.name" :options="options">
    <div class="expande-horizontal wrap">
        <v-form ref="form" class="expande-horizontal">
            <v-flex xs12 class="pa-3">
                <div class="expande-horizontal centraliza">
                    <v-flex xs12 md3>
                        <div class="expande-horizontal column">
                            <v-text-field
                                color="orange"
                                dark
                                v-model="getPayInvestment.porcentagem"
                                label="Porcentagem"
                                autofocus
                                prefix="%"
                                :rules="[v => !!v || 'Informa Aqui']"
                                v-mask="['#', '#.#', '#.##', '##.##']"
                                outlined
                                append-icon="mdi-percentage"
                            ></v-text-field>
                            <v-btn color="green" class="white--text" @click="validaPayment">Pagar Agora</v-btn>
                        </div>

                    </v-flex>
                </div>
            </v-flex>
        </v-form>
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InvestmentItem from "./components/InvestmentItem";
import ModalViewInvestment from "./components/modalViewInvestment";
export default {
  data() {
    return {
      options:[
      ]
    }
  },
  components: {
    InvestmentItem,
    ModalViewInvestment
  },
  computed: {
    ...mapGetters(["getPayInvestment", "getListPayInvestment"])
  },
  methods: {
    ...mapActions(["listPayInvestment", "payInvestment"]),
    validaPayment() {
        if(this.$refs.form.validate()) {
            this.payInvestment()
        }
    }
  },
  created() {
    this.getListPayInvestment();
  }
};
</script>
